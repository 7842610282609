import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1>SiteFilter Website</h1>
          <span>sitefilter.com</span>

      </header>
    </div>
  );
}

export default App;
